main {
  width: 100%;
  flex-grow: 1;
}
html {
  overflow-x: hidden;
  margin: 0 auto;
  min-width: 500px;
  max-width: 1500px;
  width: 85%;
  color: rgb(60, 60, 60);
}

html,
body,
* {
  box-sizing: border-box;
}

a {
  color: rgb(90, 90, 90);
  text-decoration: none;
}

h4 {
  padding: 0;
  margin: 0;
  color: rgb(30, 30, 30);
}

p {
  margin-bottom: 12px;
}

textarea {
  font-family: inherit;
}

#main-section {
  width: 80%;
  margin: 0 auto;
  margin-block-start: 4rem;
  max-width: 1200px;
}

input {
  color: rgb(50, 50, 50);
}

div {
  overflow-wrap: break-word;
}

@media only screen and (max-width: 1069px) {

}
