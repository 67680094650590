.playDiv {
  position: relative;
  margin-bottom: 7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.playImg {
  width: 75%;
  max-width: 1400px;
  border-radius: 5px;
}

.play {
  display: flex;
  width: 73%;
  margin-top: 3rem;
  padding: 1.5rem;
  min-height: 1000px;
}

.playtext {
  margin-left: 5rem;
  padding: 0rem 1rem 1rem 1rem;
  white-space: pre-line;
  width: 90%;
}

.playSelection {
  width: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.playHeader{
  margin-top: 3rem;
  font-size: 32px;
  text-decoration: underline;
}

.playSelection > div {
  padding: 0.5rem;
  width: 8rem;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid white;
  background-color: #4267b2;
  color: white;
}

.playSelection > div:hover {
  cursor: pointer;
  background-color: white;
  color: #4267b2;
  border: 1px solid #4267b2;
}

.samplePlan{
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.samplePlan > div{
  width: 45%;
  text-align:center;
  border:1px grey solid;
  border-radius: 5px;
  box-shadow: grey 4px 5px;
}

.samplePlan > div:hover{
  box-shadow: none;
}


.sampleHeader{
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-size: 20px;
  color: white;
  background-color: #4267b2;
  padding: 1.3rem;
}

.sampleBody{
  text-align:left;
  padding: 1.3rem;
}

.sampleBody > div{
  padding: .75rem;
}

.sampleFooter{
  padding: 1.3rem;
}

.lineBreak{
  position: relative;
  border: .5px grey solid;
  padding: 0 !important; 
  height: 0px;
  width: 100%;
}
.playtext > div > h3 {
  margin-bottom: 3rem;
}


@media only screen and (max-width: 1069px) {
  .playDiv {
    position: relative;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .playImg {
    width: 85%;
    border-radius: 5px;
  }
  
  .play {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin-top: 1rem;
    padding: 1.5rem;
    min-height: 30rem;
  }
  
  .playtext {
    margin-left: 0;
    padding: 0;
    width: 100%;
    font-size: 18px;
  }
  
  .playSelection {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    gap: 1rem;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 24px;
  }
  
  .playHeader{
    margin-top: 3rem;
    font-size: 32px;
    text-decoration: underline;
  }
  
  .playSelection > div {
    padding: 0.5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .samplePlan{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0rem;
    justify-content: center;
  }
  
  .samplePlan > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
