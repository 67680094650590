.backdrop {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: inherit;
  margin-top: -2rem;
}

.btn {
  align-self: center;
  width: 8rem;
  height: 2.25rem;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid white;
  background-color: #4267b2;
  color: white;
}
.btn:hover {
  cursor: pointer;
  background-color: white;
  color:#4267b2;
  border: 1px solid #4267b2;
}

.ullist {
  padding-left: 0;
}


@media only screen and (max-width: 1069px) {
  .backdrop {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: inherit;
    padding-bottom: 2rem;
    margin-top: -2rem;
  }
}
