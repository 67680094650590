.footer{
  align-self: center;
  position: absolute;
  bottom: 1;
  width: 100%;
  max-width: 1500px;
  display: flex;
  gap: 2.5rem;
  padding-left: 10%;
  padding-right: 10%;
  /* padding:calc(8px + 1.5625vw); */
}


.about{
  width: 40%;
}
.contact{
  width: 40%;
}
.links{
  width: 20%;
}


@media only screen and (min-width: 1069px) and (max-width:1100px) {
  .footer {
    gap: 1.5rem;
    padding-left: 0%;
    padding-right: 0%;
  }

  .about{
    min-width: 200px;
    width: 33%;
  }
  .contact{
    width: 33%;
  }
  .links{
    width: 30%;
  }
}



@media only screen and (max-width: 1069px) {
  .footer {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px;
    position: relative;
    flex-direction: column;
    gap: 1rem;
  }
  .about{
    padding-left: 0%;
    text-align: center;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 0;
    grid-row-end: 2;

  }
  .contact{
    width: 100%;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .links{
    margin-left: 1rem;
    width: 100%;
    text-align: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    font-size: 18px;
  }
}
