.mainHomeDiv {
  margin-bottom: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
}

.homeHeader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 3.5rem;
}
.homeHeading {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.homeShowcase {
  display: flex;
  width: 90%;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.5rem;
}
.locationHeader {
  text-align: center;
  margin-bottom: 1rem;
}

.homeAboutContainer {
  display: flex;
  flex-direction: column;
  border: 2px black solid;
  border-radius: 5px;
  box-shadow: 5px 8px grey;
  margin-bottom: 3rem;
}
.homeAboutContainer button {
  margin-bottom: 3rem;
  align-self: center;
  width: 10rem;
  height: 3.25rem;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid white;
  background-color: #4267b2;
  color: white;
}
.homeAboutContainer button:hover {
  cursor: pointer;
  background-color: white;
  color:#4267b2;
  border: 1px solid #4267b2;
}

.homeAbout {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1.5rem;
  padding-bottom: 0;
  gap: 2rem;
}
.divp1 {
  white-space: pre-line;
  width: 50%;
  overflow-wrap: break-word;
}
.divp1 h4 {
  text-align: center;
  margin-bottom: 1rem;
}
.divp2 {
  white-space: pre-line;
  width: 50%;
  overflow-wrap: break-word;
}
.divp2 h4 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Overlay for Video */
.homeDiv {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
  -webkit-user-select: none; /* Safari and older versions of Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1;
  right: 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
.homeDiv > .overlay {
  display: inline-block;
  width: 100%;
}
/* Video Ends */

/* Overlay for Images */
.homeImgs {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.homePlay,
.homeNutrition,
.homeActivity {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  padding: 0 1% 2%;
}
.textContainer {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  -webkit-user-select: none; /* Safari and older versions of Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}
.text {
  background-color: #4267b2;
  color: white;
  font-size: 24px;
  padding: 0.5rem;
  border-radius: 5px;
}
.text:hover {
  cursor: pointer;
  opacity: 0.8;
}
.homePlay:hover .homeImgs {
  opacity: 0.5;
}
.homePlay:hover .textContainer {
  opacity: 1;
}
.homeNutrition:hover .homeImgs {
  opacity: 0.3;
}
.homeNutrition:hover .textContainer {
  opacity: 1;
}
.homeActivity:hover .homeImgs {
  opacity: 0.3;
}
.homeActivity:hover .textContainer {
  opacity: 1;
}
/* Images Ends */

@media only screen and (max-width: 1069px) {
  .mainHomeDiv {
    padding-top: 0;
  }
  .homeShowcase {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .homeHeader {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
  .homeImgs {
    width: 100%;
  }
  .homeDiv > video {
    width: 125%;
    height: auto;
  }
  .homeDiv > .overlay {
    display: inline-block;
    width: 125%;
  }
  .map {
    width: 100%;
  }
  .contactContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    padding-top: 0;
  }
  .text {
    background-color: #4267b2;
    color: white;
    font-size: 24px;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .location {
    text-align: center;
  }

  .location > p {
    font-size: 18px;

  }
  .homeAbout {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .homeAboutContainer {
    border: none;
    border-radius: 5px;
    box-shadow: none;
  }

  .homeAboutContainer button {
    position: relative;
    margin-bottom: 0rem;
  }

  .divp1 {
    font-size: 18px;
    white-space: pre-line;
    width: 100%;
    overflow-wrap: break-word;
    text-align: center;
  }
  .divp1 h4 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .divp2 {
    font-size: 18px;
    white-space: pre-line;
    width: 100%;
    overflow-wrap: break-word;
    text-align: center;
  }
  .divp2 h4 {
    text-align: center;
    margin-bottom: 1rem;
  }
}


@media only screen and (min-width: 768px) {
.homeDiv > video {
  max-width: 1300px;
  width: 190%;
  height: auto;
}
.homeDiv > .overlay {
  max-width: 1300px;
  display: inline-block;
  width: 190%;
}
.homeHeader{
  font-size: 30px;
}
}