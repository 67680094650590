.servicesDiv {
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.servicesHeader {
  white-space: pre-line;
  margin-bottom: 3.5rem;
  border: 1px solid grey;
  border-radius: 5px;
  width: 62%;
  padding: 0.75rem;
}

.servicesContainer {
  display: flex;
  gap: 2.5rem;
  width: 85%;
  min-height: 700px;
  margin-bottom: 10rem;
}

.servicesContainer h3 {
font-size: 1.4rem;
margin-top: 1rem;
margin-bottom: 2rem;
}

.servicesPicture {
  width: 1000px;
  height: 575px;
  margin-bottom: 2rem;
  border-radius: 5px;
}

.play {
  padding: 0.5rem;
  border-radius: 5px;
  width: 33%;
  text-align: center;
}

.nutrition {
  padding: 0.5rem;
  border-radius: 5px;
  width: 33%;
  text-align: center;
}

.activity {
  padding: 0.5rem;
  border-radius: 5px;
  width: 33%;
  text-align: center;
}

.playImg {
  border-radius: 5px;
  width: 100%;
}
.nutritionImg {
  border-radius: 5px;
  width: 100%;
}
.activityImg {
  border-radius: 5px;
  width: 100%;
}

.ullist{
  padding-top: .5rem;
  padding-left: 0;
  padding-bottom: .5rem;
}

.ullist li{
  padding:.75rem;
  font-size: 1.15rem;
    list-style: none;
}

@media only screen and (max-width: 1069px) {
  .servicesDiv {
    padding-left: 4%;
    padding-right: 4%;
    margin-right: 0;
    min-width: 500px;
    margin-bottom: 2rem;
  }

  .servicesHeader {
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 0.5rem;
  }
  .servicesHeader > p{
    font-size: 18px;
    }
    

  .servicesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .servicesPicture {
    width: 100%;
    height: auto;
  }

  .play {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    width: 100%;
  }
  
  .nutrition {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    width: 100%;
  }
  
  .activity {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    width: 100%;
  }
  .placeholder{
    min-height: 0px;
  }
}
