.aboutDiv {
  margin-bottom: 7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aboutHeader {
  margin-bottom: 2.5rem;
}
.aboutContainer {
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 420px;
  width: 65%;
}
.divChild1 {
  width: 50%;
}

.divChild2 {
  width: 50%;
}

.pfp {
  border-radius: 5px;
  height: 25rem;
  margin-top: 1rem;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: center;
  align-self: center;
}
.house {
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  height: 25rem;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.divp1 {
  white-space: pre-line;
  width: 400px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.divp1 > h2, .divp2 >h2 {
  text-align: center;
}

.divp2 {
  white-space: pre-line;
}

@media only screen and (max-width: 1069px) {
  .aboutDiv {
    margin-bottom: 4rem;
  }

  .aboutContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  .divChild1 {
    width: 50%;
  }

  .divChild2 {
    width: 50%;
  }

  .pfp {
    border-radius: 5px;
    height: 30rem;
  }
  .house {
    border-radius: 5px;
    height: 30rem;
  }

  .divp1 {
    font-size: 18px;
    width: 400px;
  }
  .divp2 {
    font-size: 18px;
    width: 400px;
  }
}
